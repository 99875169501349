import { db, firebase } from '@/config/firebase.config.js';

const notesCollection = db.collection('notes');

export const getNotes = ({ user_id, song_id }) => {
  return notesCollection
    .where('user_id', '==', user_id)
    .where('song_id', '==', song_id)
    .get();
};

export const createNote = async ({ user_id, note, song_id }) => {
  const doc = notesCollection.doc();
  const id = doc.id;
  const payload = {
    id,
    user_id,
    note,
    song_id,
    created_at: firebase.firestore.Timestamp.now(),
  };
  await doc.set(payload);
  return payload;
};

export const updateNote = ({ id, note }) => {
  return notesCollection.doc(id).update({
    note,
    updated_at: firebase.firestore.Timestamp.now(),
  });
};

export const deleteNote = (id) => notesCollection.doc(id).delete();
