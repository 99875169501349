<template>
  <div class="overlay details-publishing-modal">
    <div class="container">
      <router-link :to="'/details/' + $route.params.id" class="back"
        ><img
          class="desktop_form_close"
          src="@/assets/img/close.svg"
          alt="close"
      /></router-link>

      <label class="title-label"><strong>Notes for:</strong></label>
      <h1>{{ title }}</h1>
      <div class="white-space"></div>
      <form>
        <textarea
          class="note-textarea"
          type="text"
          v-model="note"
          placeholder="add a new note here"
        ></textarea>
        <div class="submit-btn__container">
          <BaseButton @click.prevent="addNote">Submit note</BaseButton>
        </div>
      </form>

      <div class="white-space"></div>

      <Note
        v-for="note of notes"
        :key="note.id"
        :note="note"
        @updateNote="updateNote"
        @deleteNote="deleteNote"
      />
    </div>
  </div>
</template>

<script>
import "@/config/firebase.config.js";
import { getNotes, createNote, updateNote, deleteNote } from "@/api/noteApi.js";
import { userComputed } from "@/services/userService.js";
import { API_STATUS } from "@/constants/apiStatus.js";
import Note from "./components/Note";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;

export default {
  name: "PrivateNotes",
  components: {
    Note,
  },
  metaInfo: {
    title: "Notes",
  },
  props: {
    title: {
      type: String,
    },
    song_id: {
      type: String,
    },
  },
  data() {
    return {
      note: "",
      notes: [],
      createNoteApiStatus: IDLE,
      getNotesApiStatus: IDLE,
    };
  },
  computed: {
    ...userComputed,
  },
  watch: {
    user: {
      handler(user) {
        if (!user) return;
        this.getNotes(user);
      },
      immediate: true,
    },
  },
  methods: {
    async updateNote(params) {
      const { onComplete, id, note } = params;
      try {
        await updateNote({ id, note });
        this.notes = this.notes.map((note_item) => {
          if (note_item.id === id) {
            return {
              ...note_item,
              note,
            };
          }
          return note_item;
        });
        onComplete();
      } catch (error) {
        console.error(error);
      }
    },
    async deleteNote(id) {
      try {
        await deleteNote(id);
        this.notes = this.notes.filter((note) => note.id !== id);
      } catch (error) {
        console.error(error);
      }
    },
    async addNote() {
      console.log("adding note");
      if (!this.note) return;
      try {
        this.createNoteApiStatus = PENDING;
        const notePayload = {
          user_id: this.user.uid,
          song_id: this.song_id,
          note: this.note,
        };
        const note = await createNote(notePayload);
        console.log("response", note);
        const last_modification_date_timestamp =
          note.updated_at || note.created_at;
        this.notes.push({
          ...note,
          last_modification_date: new Intl.DateTimeFormat("en-US").format(
            last_modification_date_timestamp.toDate()
          ),
        });
        this.note = "";
        this.createNoteApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.createNoteApiStatus = ERROR;
      }
    },
    async getNotes(user) {
      try {
        this.getNotesApiStatus = PENDING;
        const response = await getNotes({
          user_id: user.uid,
          song_id: this.song_id,
        });
        console.log("response", response);
        let notes = [];
        response.docs.forEach((snap) => {
          let data = snap.data();
          const last_modification_date_timestamp =
            data.updated_at || data.created_at;
          data.last_modification_date = new Intl.DateTimeFormat("en-US").format(
            last_modification_date_timestamp.toDate()
          );
          notes.push(data);
        });
        this.notes = notes;
        this.getNotesApiStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.getNotesApiStatus = ERROR;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
  @include mobile {
  }
}

.submit-btn__container {
  display: flex;
  justify-content: flex-end;
}

.title-label {
  @include mobile {
    display: none;
  }
}

.note-textarea {
  background: none;
  border: none;
  font-size: 26px;
  font-family: "helveticaneue";
  width: 100%;
  outline: none;
}
</style>